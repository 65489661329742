<template>
  <div>
    <!-- 사업자 이름 / 등록번호 -->
    <CRow>
      <CCol col="5" class="py-2">
        <CCol col="12" class="py-1">
          사업자 이름
        </CCol>
        <CCol col="12" class="py-1">
          <input placeholder="이름을 입력하세요" style="width: 100%; border: 1px #E2E2E2 solid;" 
            v-model="company_owner_name"
          />
        </CCol>
      </CCol>
      <CCol col="7" class="py-2">
        <CCol col="12" class="py-1">
          사업자 등록번호
        </CCol>
        <CCol col="12" class="py-1">
          <input placeholder="사업자 등록번호를 입력하세요" style="width: 100%; border: 1px #E2E2E2 solid;" 
            v-model="company_regist_number"
          />
        </CCol>
      </CCol>
    </CRow>
    <!-- 업태 / 종목 -->
    <CRow>
      <CCol col="5" class="py-2">
        <CCol col="12" class="py-1">
          업태
        </CCol>
        <CCol col="12" class="py-1">
          <input placeholder="업태를 입력하세요" style="width: 100%; border: 1px #E2E2E2 solid;" 
            v-model="company_business_type"
          />
        </CCol>
      </CCol>
      <CCol col="7" class="py-2">
        <CCol col="12" class="py-1">
          종목
        </CCol>
        <CCol col="12" class="py-1">
          <input placeholder="종목을 입력하세요" style="width: 100%; border: 1px #E2E2E2 solid;" 
            v-model="company_business_item"
          />
        </CCol>
      </CCol>
    </CRow>
    <!-- 사업장 소재지 -->
    <CRow>
      <CCol col="12" class="py-2">
        사업장 소재지
      </CCol>
      <CCol col="12" class="py-2">
        <CRow>
          <CCol col="9" class="py-2">
            <input placeholder="사업장 소재지를 입력하세요" style="width: 100%; border: 1px #E2E2E2 solid;" 
              v-model="company_address"
            />
          </CCol>
          <CCol col="3" class="px-2">
            <CCol col="12" class="py-2" @click="addressSearchModal = true" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;">
              주소검색
            </CCol>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <!-- 사업자 등록증 사진등록 -->
    <CRow>
      <CCol col="12" class="py-2">
        사업자 등록증 사진등록
      </CCol>
      <CCol col="12" class="py-2">
        <CRow>
          <CCol col="6" class="px-2 py-2">
            <CCol col="12" class="py-2" style="border: 1px #E2E2E2 solid;">
              <CRow>
                <CCol col="3" class="py-2">
                  <img :src="require('@/assets/images/storeManagement_companyRegister/dummy.png')" style="width: 100%;" />
                </CCol>
                <CCol col="9" class="py-2">
                  예시와 같이<br>
                  사업자등록증 사진을 등록해주세요
                </CCol>
              </CRow>
            </CCol>
          </CCol>
          <CCol col="6" class="px-2 py-2">
            <CCol col="12" class="py-2" style="border: 1px #E2E2E2 solid;">
              <CCol col="12" class="py-2">
                파트너의 사업자 정보를 확인 후 빠르게 수리요 파트너로 등록을 도와드립니다!
              </CCol>
              <CCol col="12" class="px-2">
                <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #E2E2E2; color: ;">
                  <input ref="image" @change="uploadImg()" type="file" id="chooseFile" name="chooseFile" accept="image/*">
                  <!-- 사진 업로드 -->
                </CCol>
              </CCol>
            </CCol>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <!-- 취소 / 신청 -->
    <CRow>
      <CCol col="12" class="py-2">
        <CRow style="display: flex; align-items: center; justify-content: center;">
          <!-- <CCol col="3" class="px-4 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: gray; color: #FFFFFF">
              취소
            </CCol>
          </CCol> -->
          <CCol col="3" class="px-4 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;"
              @click="companyInformationSettingChange()"
            >
              신청
            </CCol>
          </CCol>
        </CRow>
      </CCol>
    </CRow>

    <!-- 주소 검색 모달 -->
    <CModal
      title="주소 검색"
      :show.sync="addressSearchModal"
      size="xl"
    >
      <div style="min-height: 300px;">
        <VueDaumPostcode @complete="addressSearched" />
      </div>
    </CModal>
  </div>
</template>

<script>
import { VueDaumPostcode } from 'vue-daum-postcode';
import axios from 'axios';

export default {
  name: 'c_storeManagement_companyRegister',
  components: {
    VueDaumPostcode
  },
  data: function () {
    return {
      addressSearchModal: false,
      userIndex: null,
      partnerIndex: null,
      company_owner_name: '',
      company_regist_number: '',
      company_business_type: '',
      company_business_item: '',
      company_address: '',
      company_regist_image: '',
    }
  },
  beforeMount() {
    console.log(this.$route.params.userIndex, this.$route.params.partnerIndex);
    console.log(localStorage.getItem('userIndex'), localStorage.getItem('partnerIndex'));
    this.userIndex = localStorage.getItem('userIndex');
    this.partnerIndex = localStorage.getItem('partnerIndex');
  },
  methods: {
    // 주소 검색
    addressSearched(data) {
      console.log(data.roadAddress ? data.roadAddress : data.jibunAddress);
      this.company_address = data.roadAddress ? data.roadAddress : data.jibunAddress;
      this.addressSearchModal = false;
    },
    uploadImg() {
      console.log('들어왔다')
      var image = this.$refs['image'].files[0]

      const url = URL.createObjectURL(image)
      this.image = url
      console.log(url)
      console.log(this.image)

      // 이미지 업로드
      const func = async () => {
        const formData = new FormData();
        // formData.append("files", {
        //   type: 'image/jpeg',
        //   name: 'image.jpg',
        //   uri: url
        // });
        formData.append('files', image)
  
        try {
          const result_axios = await axios.post(`https://suriyo.api.togethersoft.co.kr/files`, formData,
          {
            headers: { 
              'content-type': 'multipart/form-data',
              // 'Cache-Control': 'no-cache',
              // 'Pragma': 'no-cache',
              // 'Expires': '0'
            },
          });
          if (result_axios.status == 200) {
            console.log('이미지 업로드 성공', result_axios)
            console.log(result_axios.data.data.id)
            // 서버에 업로드된 이미지의 id
            this.company_regist_image = result_axios.data.data.id;
          } else {
            console.log('이미지 업로드 실패', err);
          }
        } catch (err) {
          console.log(err);
        }
      }
      func();
    },
    companyInformationSettingChange() {
      console.log('사업자정보 등록/변경', this.company_owner_name, this.company_regist_number, this.company_business_type, this.company_business_item, this.company_address, this.company_regist_image);
      if (this.company_owner_name == '' || this.company_regist_number == '' || this.company_business_type == '' || this.company_business_item == '' || this.company_address == '' || this.company_regist_image == '') {
        alert('정보를 입력해주세요');
      } else {
        const confirmResult = confirm('사업자 정보를 변경하시겠습니까?')
        if (confirmResult == true) {
          console.log('ok');
          const data = {
            "company_owner_name": this.company_owner_name,
            "company_regist_number": this.company_regist_number,
            "company_business_type": this.company_business_type,
            "company_business_item": this.company_business_item,
            "company_regist_image": this.company_regist_image,
            "address": this.company_address,
          }
          axios.patch(`https://suriyo.api.togethersoft.co.kr/items/partner/${this.partnerIndex}`, data)
          .then((res) => {
            console.log(res);
            alert('사업자 정보가 변경되었습니다.')
          })
          .catch((err) => {
            console.log(err);
          })
        }
      }
    }
  },
}
</script>
