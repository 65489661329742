<template>
  <div>
    <!-- 옵션명 / 가격 -->
    <CRow>
      <CCol col="12" class="py-2">
        <CRow>
          <CCol col="7" class="py-2">
            옵션명
          </CCol>
          <CCol col="3" class="py-2">
            가격
          </CCol>
          <CCol col="2" class="py-2">
            <!-- 공백 -->
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <!-- 상세정보 -->
    <CRow>
      <!-- 직접 방문 점검비 -->
      <CCol col="12" class="py-1">
        <CRow>
          <CCol col="7" class="py-2">
            직접 방문 점검비
          </CCol>
          <CCol col="3" class="py-2">
            <CCol col="12" class="py-2" style="border: 1px #E2E2E2 solid;">
              <input placeholder="금액을 입력하세요" style="width: 80%; border: none;"
                v-model="direct_visit_price"
              >
              원
            </CCol>
          </CCol>
          <!-- <CCol col="2" class="px-2 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;">
              등록
            </CCol>
          </CCol> -->
        </CRow>
      </CCol>
      <!-- 출장 방문 점검비 -->
      <CCol col="12" class="py-1">
        <CRow>
          <CCol col="7" class="py-2">
            출장 방문 점검비
          </CCol>
          <CCol col="3" class="py-2">
            <CCol col="12" class="py-2" style="border: 1px #E2E2E2 solid;">
              <input placeholder="금액을 입력하세요" style="width: 80%; border: none;"
                v-model="business_trip_price"
              >
              원
            </CCol>
          </CCol>
          <!-- <CCol col="2" class="px-2 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;">
              등록
            </CCol>
          </CCol> -->
        </CRow>
      </CCol>
      <!-- 직접 방문 부품교체 공임비 -->
      <CCol col="12" class="py-1">
        <CRow>
          <CCol col="7" class="py-2">
            직접 방문 부품교체 공임비
          </CCol>
          <CCol col="3" class="py-2">
            <CCol col="12" class="py-2" style="border: 1px #E2E2E2 solid;">
              <input placeholder="금액을 입력하세요" style="width: 80%; border: none;"
                v-model="direct_visit_part_replace_price"
              >
              원
            </CCol>
          </CCol>
          <!-- <CCol col="2" class="px-2 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;">
              등록
            </CCol>
          </CCol> -->
        </CRow>
      </CCol>
      <!-- 출장 방문 부품교체 공임비 -->
      <CCol col="12" class="py-1">
        <CRow>
          <CCol col="7" class="py-2">
            출장 방문 부품교체 공임비
          </CCol>
          <CCol col="3" class="py-2">
            <CCol col="12" class="py-2" style="border: 1px #E2E2E2 solid;">
              <input placeholder="금액을 입력하세요" style="width: 80%; border: none;"
                v-model="business_trip_part_replace_price"
              >
              원
            </CCol>
          </CCol>
          <!-- <CCol col="2" class="px-2 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;">
              등록
            </CCol>
          </CCol> -->
        </CRow>
      </CCol>
      <!-- 커스텀 영역 -->
      <!-- <CCol col="12" class="py-1">
        <CRow>
          <CCol col="7" class="py-2">
            <input placeholder="옵션명을 입력하세요" style="width: 80%;">
          </CCol>
          <CCol col="3" class="py-2">
            <CCol col="12" class="py-2" style="border: 1px #E2E2E2 solid;">
              <input placeholder="금액을 입력하세요" style="width: 80%; border: none;">
              원
            </CCol>
          </CCol>
          <CCol col="2" class="px-2 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;">
              등록
            </CCol>
          </CCol>
        </CRow>
      </CCol>
      <CCol col="12" class="py-1">
        <CRow>
          <CCol col="7" class="py-2">
            <input placeholder="옵션명을 입력하세요" style="width: 80%;">
          </CCol>
          <CCol col="3" class="py-2">
            <CCol col="12" class="py-2" style="border: 1px #E2E2E2 solid;">
              <input placeholder="금액을 입력하세요" style="width: 80%; border: none;">
              원
            </CCol>
          </CCol>
          <CCol col="2" class="px-2 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;">
              등록
            </CCol>
          </CCol>
        </CRow>
      </CCol> -->
    </CRow>
    <!-- 닫기 / 옵션추가 -->
    <CRow>
      <CCol col="12" class="py-2">
        <CRow style="display: flex; align-items: center; justify-content: center;">
          <!-- <CCol col="3" class="px-4 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: gray; color: #FFFFFF;">
              닫기
            </CCol>
          </CCol> -->
          <!-- <CCol col="3" class="px-4 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;">
              옵션추가
            </CCol>
          </CCol> -->
          <CCol col="3" class="px-4 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;"
              @click="workTimeSetting()"
            >
              옵션 설정
            </CCol>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import axios from 'axios';

export default {
  name: 'c_storeManagement_workOptionSetting',
  components: {
    vSelect
  },
  data: function () {
    return {
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
      inventory_product_add_modal: false,
      inventory_product_search_modal: false,
      // 재고상품 추가
      inventory_product_add_title: '',
      inventory_product_add_price: 0,
      inventory_product_add_infomation: '',
      inventory_product_add_option_title: '',
      inventory_product_add_option_price: 0,
      // 재고상품 검색
      inventory_product_search_title: '',

      // 수리의뢰옵션
      direct_visit_price: null,
      business_trip_price: null,
      direct_visit_part_replace_price: null,
      business_trip_part_replace_price: null,

      userIndex: null,
      partnerIndex: null,
    }
  },
  beforeMount() {
    console.log(this.$route.params.userIndex, this.$route.params.partnerIndex);
    console.log(localStorage.getItem('userIndex'), localStorage.getItem('partnerIndex'));
    this.userIndex = localStorage.getItem('userIndex');
    this.partnerIndex = localStorage.getItem('partnerIndex');
  },
  methods: {
    isNumeric(str) {
      if (typeof str != "string") return false // we only process strings!  
      return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    },
    workTimeSetting() {
      console.log(
        this.direct_visit_price,
        this.business_trip_price,
        this.direct_visit_part_replace_price,
        this.business_trip_part_replace_price,
      );
      if (
        this.direct_visit_price == null ||
        this.business_trip_price == null ||
        this.direct_visit_part_replace_price == null ||
        this.business_trip_part_replace_price == null ||
        this.isNumeric(this.direct_visit_price) == false ||
        this.isNumeric(this.business_trip_price) == false ||
        this.isNumeric(this.direct_visit_part_replace_price) == false ||
        this.isNumeric(this.business_trip_part_replace_price) == false || 
        this.direct_visit_price <= 0 || 
        this.business_trip_price <= 0 || 
        this.direct_visit_part_replace_price <= 0 || 
        this.business_trip_part_replace_price <= 0
      ) {
        alert('올바른 정보를 입력해주세요');
      } else {
        let productOptionObj = {
          "direct_visit_price": this.direct_visit_price,
          "business_trip_price": this.business_trip_price,
          "direct_visit_part_replace_price": this.direct_visit_part_replace_price,
          "business_trip_part_replace_price": this.business_trip_part_replace_price,
        }
        console.log(productOptionObj);
        
        const confirmResult = confirm('영업 시간을 변경하시겠습니까?')
        if (confirmResult == true) {
          console.log('ok');
          const data = {
            "product_option_json": productOptionObj,
          }
          axios.patch(`https://suriyo.api.togethersoft.co.kr/items/partner/${this.partnerIndex}`, data)
          .then((res) => {
            console.log(res);
            alert('수리 의뢰 옵션이 변경되었습니다.')
          })
          .catch((err) => {
            console.log(err);
          })
        }
      }
    }
  },
}
</script>
