<template>
  <div>
    <CRow>
      <!-- 매장 소개 -->
      <CCol col="6" class="py-2">
        <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center;">
          매장 소개를 입력하세요
        </CCol>
        <CCol col="12" class="py-2">
          <textarea type="text" placeholder="매장에 대한 소개를 입력하세요. 매장을 쉽게 찾아가기 위한 상세정보, 주차가능 여부에 대한 정보도 좋아요!" style="width: 100%; min-height: 300px; border: 1px #E2E2E2 solid;"
            v-model="companyInformation"
          ></textarea>
        </CCol>
      </CCol>
      <!-- 매장 이미지 -->
      <CCol col="6" class="py-2">
        <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center;">
          매장 이미지 변경
        </CCol>
        <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center;">
          매장 이미지 변경은<br>
          파트너스 고객센터로 문의해주세요
        </CCol>
      </CCol>
    </CRow>
    <!-- 취소 / 저장 -->
    <CRow>
      <CCol col="12" class="py-2">
        <CRow style="display: flex; align-items: center; justify-content: center;">
          <!-- <CCol col="3" class="px-4 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: gray; color: #FFFFFF;">
              취소
            </CCol>
          </CCol> -->
          <CCol col="3" class="px-4 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;"
              @click="companyInformationChange()"
            >
              저장
            </CCol>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import axios from 'axios';

export default {
  name: 'c_storeManagement_companyInfoSetting',
  components: {
    vSelect
  },
  data: function () {
    return {
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
      inventory_product_add_modal: false,
      inventory_product_search_modal: false,
      // 재고상품 추가
      inventory_product_add_title: '',
      inventory_product_add_price: 0,
      inventory_product_add_infomation: '',
      inventory_product_add_option_title: '',
      inventory_product_add_option_price: 0,
      // 재고상품 검색
      inventory_product_search_title: '',

      // 입력된 매장 소개
      companyInformation: '',
      userIndex: null,
      partnerIndex: null,
    }
  },
  beforeMount() {
    console.log(this.$route.params.userIndex, this.$route.params.partnerIndex);
    console.log(localStorage.getItem('userIndex'), localStorage.getItem('partnerIndex'));
    this.userIndex = localStorage.getItem('userIndex');
    this.partnerIndex = localStorage.getItem('partnerIndex');
  },
  methods: {
    companyInformationChange() {
      console.log('매장 소개 변경', this.companyInformation);
      const confirmResult = confirm('매장 소개를 변경하시겠습니까?')
      if (confirmResult == true) {
        console.log('ok');
        const data = {
          "information": this.companyInformation,
        }
        axios.patch(`https://suriyo.api.togethersoft.co.kr/items/partner/${this.partnerIndex}`, data)
        .then((res) => {
          console.log(res);
          alert('매장 소개가 변경되었습니다.')
        })
        .catch((err) => {
          console.log(err);
        })
      }
    }
  },
}
</script>
