<template>
  <div>
    <!-- 혜택 제목 -->
    <CRow>
      <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: center;">
        혜택 이름
      </CCol>
    </CRow>
    <!-- 혜택 상세 -->
    <CRow>
      <CCol col="12" class="py-2">
        <CRow>
          <CCol col="8" class="py-2">
            <input placeholder="혜택 이름을 입력하세요" style="width: 100%; border: 1px #E2E2E2 solid;" />
          </CCol>
          <CCol col="2" class="py-2">
            <input type="checkbox">
            직접방문
          </CCol>
          <CCol col="2" class="py-2">
            <input type="checkbox">
            출장방문
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <!-- 가격 제목 -->
    <CRow>
      <CCol col="8" class="py-2" style="display: flex; align-items: center; justify-content: center;">
        할인 가격
      </CCol>
    </CRow>
    <!-- 가격 상세 -->
    <CRow>
      <CCol col="8" class="py-1">
        <CRow>
          <CCol col="3" class="py-2">
            <input placeholder="금액" style="width: 100%; border: 1px #E2E2E2 solid;" />
          </CCol>
          <CCol col="3" class="py-2">
            원 이상 결제시
          </CCol>
          <CCol col="3" class="py-2">
            <input placeholder="금액" style="width: 100%; border: 1px #E2E2E2 solid;" />
          </CCol>
          <CCol col="3" class="py-2">
            원 할인
          </CCol>
        </CRow>
      </CCol>
      <CCol col="8" class="py-1">
        <CRow>
          <CCol col="3" class="py-2">
            <input placeholder="금액" style="width: 100%; border: 1px #E2E2E2 solid;" />
          </CCol>
          <CCol col="3" class="py-2">
            원 이상 결제시
          </CCol>
          <CCol col="3" class="py-2">
            <input placeholder="금액" style="width: 100%; border: 1px #E2E2E2 solid;" />
          </CCol>
          <CCol col="3" class="py-2">
            원 할인
          </CCol>
        </CRow>
      </CCol>
      <CCol col="8" class="py-1">
        <CRow>
          <CCol col="3" class="py-2">
            <input placeholder="금액" style="width: 100%; border: 1px #E2E2E2 solid;" />
          </CCol>
          <CCol col="3" class="py-2">
            원 이상 결제시
          </CCol>
          <CCol col="3" class="py-2">
            <input placeholder="금액" style="width: 100%; border: 1px #E2E2E2 solid;" />
          </CCol>
          <CCol col="3" class="py-2">
            원 할인
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <!-- 기한 -->
    <CRow>
      <CCol col="12" class="py-2">
        <CRow>
          <CCol col="1" class="py-2">
            기한
          </CCol>
          <CCol col="3" class="py-2">
            <Datepicker v-model="calculate_start"></Datepicker>
          </CCol>
          <CCol col="1" class="py-2">
            ~
          </CCol>
          <CCol col="3" class="py-2">
            <Datepicker v-model="calculate_end"></Datepicker>
          </CCol>
          <CCol col="2" class="px-2 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;">
              등록
            </CCol>
          </CCol>
          <CCol col="2" class="px-2 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; border: 1px red solid; color: red;">
              종료
            </CCol>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import Datepicker from 'vuejs-datepicker';

export default {
  name: 'c_storeManagement_discountSetting',
  components: {
    vSelect,
    Datepicker
  },
  data: function () {
    return {
      calculate_start: '',
      calculate_end: '',
    }
  }
}
</script>
