<template>
  <div class="px-5">
    <!-- 제목 -->
    <CRow>
      <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center;">
        영업 상태를 설정하세요.
      </CCol>
    </CRow>
    <!-- 영업중 / 출장중 / 준비중 -->
    <CRow>
      <CCol col="12" class="py-2">
        <CRow>
          <CCol col="4" class="px-4 py-2">
            <CCol col="12" class="py-2" style="border: 1px #19C1FF solid;"
              @click="selectedWorkState = '영업중'"
              v-if="selectedWorkState == '영업중'"
            >
              <img :src="require('@/assets/images/storeManagement_workState/greenCircle.png')" style="width: 5%;" />
              영업중
            </CCol>
            <CCol col="12" class="py-2" style="border: 1px #707070 solid;"
              @click="selectedWorkState = '영업중'"
              v-if="selectedWorkState != '영업중'"
            >
              <img :src="require('@/assets/images/storeManagement_workState/greenCircle.png')" style="width: 5%;" />
              영업중
            </CCol>
          </CCol>
          <CCol col="4" class="px-4 py-2">
            <CCol col="12" class="py-2" style="border: 1px #19C1FF solid;"
              @click="selectedWorkState = '출장중'"
              v-if="selectedWorkState == '출장중'"
            >
              <img :src="require('@/assets/images/storeManagement_workState/orangeCircle.png')" style="width: 5%;" />
              출장중
            </CCol>
            <CCol col="12" class="py-2" style="border: 1px #707070 solid;"
              @click="selectedWorkState = '출장중'"
              v-if="selectedWorkState != '출장중'"
            >
              <img :src="require('@/assets/images/storeManagement_workState/orangeCircle.png')" style="width: 5%;" />
              출장중
            </CCol>
          </CCol>
          <CCol col="4" class="px-4 py-2">
            <CCol col="12" class="py-2" style="border: 1px #19C1FF solid;"
              @click="selectedWorkState = '준비중'"
              v-if="selectedWorkState == '준비중'"
            >
              <img :src="require('@/assets/images/storeManagement_workState/grayCircle.png')" style="width: 5%;" />
              준비중
            </CCol>
            <CCol col="12" class="py-2" style="border: 1px #707070 solid;"
              @click="selectedWorkState = '준비중'"
              v-if="selectedWorkState != '준비중'"
            >
              <img :src="require('@/assets/images/storeManagement_workState/grayCircle.png')" style="width: 5%;" />
              준비중
            </CCol>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <!-- 임시중지 -->
    <!-- <CRow>
      <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center;">
        영업 임시중지
      </CCol>
    </CRow> -->
    <!-- 임시중지 시간 -->
    <!-- <CRow>
      <CCol col="12" class="py-2">
        <CRow>
          <CCol col="4" class="px-4 py-2">
            <CCol col="12" class="py-4" style="background-color: #E2E2E2; display: flex; align-items: center; justify-content: center;">
              30분
            </CCol>
          </CCol>
          <CCol col="4" class="px-4 py-2">
            <CCol col="12" class="py-4" style="background-color: #E2E2E2; display: flex; align-items: center; justify-content: center;">
              1시간
            </CCol>
          </CCol>
          <CCol col="4" class="px-4 py-2">
            <CCol col="12" class="py-4" style="background-color: #E2E2E2; display: flex; align-items: center; justify-content: center;">
              1시간 30분
            </CCol>
          </CCol>
        </CRow>
        <CRow>
          <CCol col="4" class="px-4 py-2">
            <CCol col="12" class="py-4" style="background-color: #E2E2E2; display: flex; align-items: center; justify-content: center;">
              2시간
            </CCol>
          </CCol>
          <CCol col="4" class="px-4 py-2">
            <CCol col="12" class="py-4" style="background-color: #E2E2E2; display: flex; align-items: center; justify-content: center;">
              3시간
            </CCol>
          </CCol>
          <CCol col="4" class="px-4 py-2">
            <CCol col="12" class="py-4" style="background-color: #E2E2E2; display: flex; align-items: center; justify-content: center;">
              4시간
            </CCol>
          </CCol>
        </CRow>
      </CCol>
    </CRow> -->
    <!-- 취소 / 설정 -->
    <CRow>
      <CCol col="12" class="py-2">
        <CRow style="display: flex; align-items: center; justify-content: center;">
          <!-- <CCol col="3" class="px-4 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; border: 1px #E2E2E2 solid;">
              취소
            </CCol>
          </CCol> -->
          <CCol col="3" class="px-4 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;"
              @click="workStateChange()"
            >
              설정
            </CCol>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import axios from 'axios';

export default {
  name: 'c_storeManagement_workState',
  components: {
    vSelect
  },
  data() {
    return {
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
      inventory_product_add_modal: false,
      inventory_product_search_modal: false,
      // 재고상품 추가
      inventory_product_add_title: '',
      inventory_product_add_price: 0,
      inventory_product_add_infomation: '',
      inventory_product_add_option_title: '',
      inventory_product_add_option_price: 0,
      // 재고상품 검색
      inventory_product_search_title: '',

      // 선택된 영업 상태
      selectedWorkState: '',
      userIndex: null,
      partnerIndex: null,
    }
  },
  beforeMount() {
    console.log(this.$route.params.userIndex, this.$route.params.partnerIndex);
    console.log(localStorage.getItem('userIndex'), localStorage.getItem('partnerIndex'));
    this.userIndex = localStorage.getItem('userIndex');
    this.partnerIndex = localStorage.getItem('partnerIndex');
  },
  methods: {
    workStateChange() {
      console.log('영업 상태 변경', this.selectedWorkState);
      if (this.selectedWorkState == '') {
        alert('영업 상태를 선택해주세요');
      } else {
        const confirmResult = confirm('영업 상태를 변경하시겠습니까?')
        if (confirmResult == true) {
          console.log('ok');
          const data = {
            "work_state": this.selectedWorkState,
          }
          axios.patch(`https://suriyo.api.togethersoft.co.kr/items/partner/${this.partnerIndex}`, data)
          .then((res) => {
            console.log(res);
            alert('영업 상태가 변경되었습니다.')
          })
          .catch((err) => {
            console.log(err);
          })
        }
      }
    }
  },
}
</script>
