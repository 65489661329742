<template>
  <div>
    <!-- 영업일 -->
    <CRow>
      <CCol col="12" class="py-2">
        영업일 설정
      </CCol>
    </CRow>
    <!-- 영업일 상세 -->
    <CRow>
      <!-- 월~금 -->
      <CCol col="12" class="py-2">
        <CRow>
          <CCol col="1">
            월~금
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '오전00시', 
              '오전01시', 
              '오전02시', 
              '오전03시', 
              '오전04시', 
              '오전05시', 
              '오전06시', 
              '오전07시', 
              '오전08시', 
              '오전09시', 
              '오전10시', 
              '오전11시', 
              '오전12시', 
              '오후01시', 
              '오후02시', 
              '오후03시', 
              '오후04시', 
              '오후05시', 
              '오후06시', 
              '오후07시', 
              '오후08시', 
              '오후09시', 
              '오후10시', 
              '오후11시', 
            ]"
            v-model="workTime1_1"
          ></vSelect>
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '00분', 
              '10분', 
              '20분', 
              '30분', 
              '40분', 
              '50분', 
            ]"
            v-model="workTime1_2"
          ></vSelect>
          </CCol>
          <CCol col="2">
            부터
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '오전00시', 
              '오전01시', 
              '오전02시', 
              '오전03시', 
              '오전04시', 
              '오전05시', 
              '오전06시', 
              '오전07시', 
              '오전08시', 
              '오전09시', 
              '오전10시', 
              '오전11시', 
              '오전12시', 
              '오후01시', 
              '오후02시', 
              '오후03시', 
              '오후04시', 
              '오후05시', 
              '오후06시', 
              '오후07시', 
              '오후08시', 
              '오후09시', 
              '오후10시', 
              '오후11시', 
            ]"
            v-model="workTime1_3"
          ></vSelect>
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '00분', 
              '10분', 
              '20분', 
              '30분', 
              '40분', 
              '50분', 
            ]"
            v-model="workTime1_4"
          ></vSelect>
          </CCol>
          <CCol col="1">
            까지
          </CCol>
        </CRow>
      </CCol>
      <!-- 토 -->
      <CCol col="12" class="py-2">
        <CRow>
          <CCol col="1">
            토요일
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '오전00시', 
              '오전01시', 
              '오전02시', 
              '오전03시', 
              '오전04시', 
              '오전05시', 
              '오전06시', 
              '오전07시', 
              '오전08시', 
              '오전09시', 
              '오전10시', 
              '오전11시', 
              '오전12시', 
              '오후01시', 
              '오후02시', 
              '오후03시', 
              '오후04시', 
              '오후05시', 
              '오후06시', 
              '오후07시', 
              '오후08시', 
              '오후09시', 
              '오후10시', 
              '오후11시', 
            ]"
            v-model="workTime2_1"
          ></vSelect>
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '00분', 
              '10분', 
              '20분', 
              '30분', 
              '40분', 
              '50분', 
            ]"
            v-model="workTime2_2"
          ></vSelect>
          </CCol>
          <CCol col="2">
            부터
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '오전00시', 
              '오전01시', 
              '오전02시', 
              '오전03시', 
              '오전04시', 
              '오전05시', 
              '오전06시', 
              '오전07시', 
              '오전08시', 
              '오전09시', 
              '오전10시', 
              '오전11시', 
              '오전12시', 
              '오후01시', 
              '오후02시', 
              '오후03시', 
              '오후04시', 
              '오후05시', 
              '오후06시', 
              '오후07시', 
              '오후08시', 
              '오후09시', 
              '오후10시', 
              '오후11시', 
            ]"
            v-model="workTime2_3"
          ></vSelect>
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '00분', 
              '10분', 
              '20분', 
              '30분', 
              '40분', 
              '50분', 
            ]"
            v-model="workTime2_4"
          ></vSelect>
          </CCol>
          <CCol col="1">
            까지
          </CCol>
        </CRow>
      </CCol>
      <!-- 일 -->
      <CCol col="12" class="py-2">
        <CRow>
          <CCol col="1">
            일요일
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '오전00시', 
              '오전01시', 
              '오전02시', 
              '오전03시', 
              '오전04시', 
              '오전05시', 
              '오전06시', 
              '오전07시', 
              '오전08시', 
              '오전09시', 
              '오전10시', 
              '오전11시', 
              '오전12시', 
              '오후01시', 
              '오후02시', 
              '오후03시', 
              '오후04시', 
              '오후05시', 
              '오후06시', 
              '오후07시', 
              '오후08시', 
              '오후09시', 
              '오후10시', 
              '오후11시', 
            ]"
            v-model="workTime3_1"
          ></vSelect>
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '00분', 
              '10분', 
              '20분', 
              '30분', 
              '40분', 
              '50분', 
            ]"
            v-model="workTime3_2"
          ></vSelect>
          </CCol>
          <CCol col="2">
            부터
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '오전00시', 
              '오전01시', 
              '오전02시', 
              '오전03시', 
              '오전04시', 
              '오전05시', 
              '오전06시', 
              '오전07시', 
              '오전08시', 
              '오전09시', 
              '오전10시', 
              '오전11시', 
              '오전12시', 
              '오후01시', 
              '오후02시', 
              '오후03시', 
              '오후04시', 
              '오후05시', 
              '오후06시', 
              '오후07시', 
              '오후08시', 
              '오후09시', 
              '오후10시', 
              '오후11시', 
            ]"
            v-model="workTime3_3"
          ></vSelect>
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '00분', 
              '10분', 
              '20분', 
              '30분', 
              '40분', 
              '50분', 
            ]"
            v-model="workTime3_4"
          ></vSelect>
          </CCol>
          <CCol col="1">
            까지
          </CCol>
        </CRow>
      </CCol>
      <!-- 공휴일 -->
      <CCol col="12" class="py-2">
        <CRow>
          <CCol col="1">
            공휴일
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '오전00시', 
              '오전01시', 
              '오전02시', 
              '오전03시', 
              '오전04시', 
              '오전05시', 
              '오전06시', 
              '오전07시', 
              '오전08시', 
              '오전09시', 
              '오전10시', 
              '오전11시', 
              '오전12시', 
              '오후01시', 
              '오후02시', 
              '오후03시', 
              '오후04시', 
              '오후05시', 
              '오후06시', 
              '오후07시', 
              '오후08시', 
              '오후09시', 
              '오후10시', 
              '오후11시', 
            ]"
            v-model="workTime4_1"
          ></vSelect>
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '00분', 
              '10분', 
              '20분', 
              '30분', 
              '40분', 
              '50분', 
            ]"
            v-model="workTime4_2"
          ></vSelect>
          </CCol>
          <CCol col="2">
            부터
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '오전00시', 
              '오전01시', 
              '오전02시', 
              '오전03시', 
              '오전04시', 
              '오전05시', 
              '오전06시', 
              '오전07시', 
              '오전08시', 
              '오전09시', 
              '오전10시', 
              '오전11시', 
              '오전12시', 
              '오후01시', 
              '오후02시', 
              '오후03시', 
              '오후04시', 
              '오후05시', 
              '오후06시', 
              '오후07시', 
              '오후08시', 
              '오후09시', 
              '오후10시', 
              '오후11시', 
            ]"
            v-model="workTime4_3"
          ></vSelect>
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '00분', 
              '10분', 
              '20분', 
              '30분', 
              '40분', 
              '50분', 
            ]"
            v-model="workTime4_4"
          ></vSelect>
          </CCol>
          <CCol col="1">
            까지
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <!-- 출장가능시간 -->
    <CRow>
      <CCol col="12" class="py-2">
        출장가능시간 설정
      </CCol>
    </CRow>
    <!-- 출장가능시간 상세 -->
    <CRow>
      <!-- 영업일 -->
      <CCol col="12" class="py-2">
        <CRow>
          <CCol col="1">
            영업일
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '오전00시', 
              '오전01시', 
              '오전02시', 
              '오전03시', 
              '오전04시', 
              '오전05시', 
              '오전06시', 
              '오전07시', 
              '오전08시', 
              '오전09시', 
              '오전10시', 
              '오전11시', 
              '오전12시', 
              '오후01시', 
              '오후02시', 
              '오후03시', 
              '오후04시', 
              '오후05시', 
              '오후06시', 
              '오후07시', 
              '오후08시', 
              '오후09시', 
              '오후10시', 
              '오후11시', 
            ]"
            v-model="deliveryTime1"
          ></vSelect>
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '00분', 
              '10분', 
              '20분', 
              '30분', 
              '40분', 
              '50분', 
            ]"
            v-model="deliveryTime2"
          ></vSelect>
          </CCol>
          <CCol col="2">
            부터
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '오전00시', 
              '오전01시', 
              '오전02시', 
              '오전03시', 
              '오전04시', 
              '오전05시', 
              '오전06시', 
              '오전07시', 
              '오전08시', 
              '오전09시', 
              '오전10시', 
              '오전11시', 
              '오전12시', 
              '오후01시', 
              '오후02시', 
              '오후03시', 
              '오후04시', 
              '오후05시', 
              '오후06시', 
              '오후07시', 
              '오후08시', 
              '오후09시', 
              '오후10시', 
              '오후11시', 
            ]"
            v-model="deliveryTime3"
          ></vSelect>
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '00분', 
              '10분', 
              '20분', 
              '30분', 
              '40분', 
              '50분', 
            ]"
            v-model="deliveryTime4"
          ></vSelect>
          </CCol>
          <CCol col="1">
            까지
          </CCol>
        </CRow>
      </CCol>
      <!-- 시간대 -->
      <CCol col="12" class="py-2">
        <CRow>
          <CCol col="1">
            <!-- 공백 -->
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '1시간', 
              '2시간', 
              '3시간', 
              '6시간', 
              '12시간', 
            ]"
            v-model="deliveryTime5"
          ></vSelect>
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '00분', 
              '10분', 
              '20분', 
              '30분', 
              '40분', 
              '50분', 
            ]"
            v-model="deliveryTime6"
          ></vSelect>
          </CCol>
          <CCol col="2">
            간격
          </CCol>
          <CCol col="2">
            <vSelect :options="[
              '1대', 
              '2대', 
              '3대', 
              '4대', 
              '5대', 
              '6대', 
            ]"
            v-model="deliveryTime7"
          ></vSelect>
          </CCol>
          <CCol col="3">
            출장예약 가능
          </CCol>
        </CRow>
      </CCol>
    </CRow>
    <!-- 취소 / 저장 -->
    <CRow>
      <CCol col="12" class="py-2">
        <CRow style="display: flex; align-items: center; justify-content: center;">
          <!-- <CCol col="3" class="px-4 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; border: 1px #E2E2E2 solid;">
              취소
            </CCol>
          </CCol> -->
          <CCol col="3" class="px-4 py-2">
            <CCol col="12" class="py-2" style="display: flex; align-items: center; justify-content: center; background-color: #19C1FF; color: #FFFFFF;"
              @click="workTimeSetting()"
            >
              저장
            </CCol>
          </CCol>
        </CRow>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import 'vue-select/dist/vue-select.css';
import axios from 'axios';

export default {
  name: 'c_storeManagement_workTimeSetting',
  components: {
    vSelect
  },
  data: function () {
    return {
      show: true,
      isCollapsed: true,
      loremIpsum: 'Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl ut aliquip ex ea commodo consequat.',
      inventory_product_add_modal: false,
      inventory_product_search_modal: false,
      // 재고상품 추가
      inventory_product_add_title: '',
      inventory_product_add_price: 0,
      inventory_product_add_infomation: '',
      inventory_product_add_option_title: '',
      inventory_product_add_option_price: 0,
      // 재고상품 검색
      inventory_product_search_title: '',

      // 영업일
      workTime1_1: '',
      workTime1_2: '',
      workTime1_3: '',
      workTime1_4: '',
      workTime2_1: '',
      workTime2_2: '',
      workTime2_3: '',
      workTime2_4: '',
      workTime3_1: '',
      workTime3_2: '',
      workTime3_3: '',
      workTime3_4: '',
      workTime4_1: '',
      workTime4_2: '',
      workTime4_3: '',
      workTime4_4: '',
      // 출장가능시간
      deliveryTime1: '',
      deliveryTime2: '',
      deliveryTime3: '',
      deliveryTime4: '',
      deliveryTime5: '',
      deliveryTime6: '',
      deliveryTime7: '',

      userIndex: null,
      partnerIndex: null,
    }
  },
  beforeMount() {
    console.log(this.$route.params.userIndex, this.$route.params.partnerIndex);
    console.log(localStorage.getItem('userIndex'), localStorage.getItem('partnerIndex'));
    this.userIndex = localStorage.getItem('userIndex');
    this.partnerIndex = localStorage.getItem('partnerIndex');
  },
  methods: {
    workTimeSetting() {
      console.log(
        this.workTime1_1,
        this.workTime1_2,
        this.workTime1_3,
        this.workTime1_4,
        this.workTime2_1,
        this.workTime2_2,
        this.workTime2_3,
        this.workTime2_4,
        this.workTime3_1,
        this.workTime3_2,
        this.workTime3_3,
        this.workTime3_4,
        this.workTime4_1,
        this.workTime4_2,
        this.workTime4_3,
        this.workTime4_4,
        this.deliveryTime1,
        this.deliveryTime2,
        this.deliveryTime3,
        this.deliveryTime4,
        this.deliveryTime5,
        this.deliveryTime6,
        this.deliveryTime7
      );
      if (
        this.workTime1_1 == '' ||
        this.workTime1_2 == '' ||
        this.workTime1_3 == '' ||
        this.workTime1_4 == '' ||
        this.workTime2_1 == '' ||
        this.workTime2_2 == '' ||
        this.workTime2_3 == '' ||
        this.workTime2_4 == '' ||
        this.workTime3_1 == '' ||
        this.workTime3_2 == '' ||
        this.workTime3_3 == '' ||
        this.workTime3_4 == '' ||
        this.workTime4_1 == '' ||
        this.workTime4_2 == '' ||
        this.workTime4_3 == '' ||
        this.workTime4_4 == '' ||
        this.deliveryTime1 == '' ||
        this.deliveryTime2 == '' ||
        this.deliveryTime3 == '' ||
        this.deliveryTime4 == '' ||
        this.deliveryTime5 == '' ||
        this.deliveryTime6 == '' ||
        this.deliveryTime7 == ''
      ) {
        alert('정보를 입력해주세요');
      } else {
        let workTimeObj = {
          "mon_fri_start_hour": this.workTime1_1,
          "mon_fri_start_minute": this.workTime1_2,
          "mon_fri_end_hour": this.workTime1_3,
          "mon_fri_end_minute": this.workTime1_4,
          "sat_start_hour": this.workTime2_1,
          "sat_start_minute": this.workTime2_2,
          "sat_end_hour": this.workTime2_3,
          "sat_end_minute": this.workTime2_4,
          "sun_start_hour": this.workTime3_1,
          "sun_start_minute": this.workTime3_2,
          "sun_end_hour": this.workTime3_3,
          "sun_end_minute": this.workTime3_4,
          "holi_fri_start_hour": this.workTime4_1,
          "holi_fri_start_minute": this.workTime4_2,
          "holi_fri_end_hour": this.workTime4_3,
          "holi_fri_end_minute": this.workTime4_4,
        }
        let businessTripTimeObj = {
          "start_hour": this.deliveryTime1,
          "start_minute": this.deliveryTime2,
          "end_hour": this.deliveryTime3,
          "end_minute": this.deliveryTime4,
          "interval_hour": this.deliveryTime5,
          "interval_minute": this.deliveryTime6,
          "max_quantity": this.deliveryTime7,
        }
        console.log(workTimeObj);
        console.log(businessTripTimeObj);
        
        const confirmResult = confirm('영업 시간을 변경하시겠습니까?')
        if (confirmResult == true) {
          console.log('ok');
          const data = {
            "work_time": workTimeObj,
            "business_trip_time": businessTripTimeObj,
          }
          axios.patch(`https://suriyo.api.togethersoft.co.kr/items/partner/${this.partnerIndex}`, data)
          .then((res) => {
            console.log(res);
            alert('영업 시간이 변경되었습니다.')
          })
          .catch((err) => {
            console.log(err);
          })
        }
      }
    }
  },
}
</script>
